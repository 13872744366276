<template>
    <div class="text-center d-block">
      <!-- Show the install or reinstall button -->
      <button v-if="canShowInstallButton" @click="handleInstall" class="install-button mr-1">
        📥 {{ buttonText }}
      </button>
      <!-- Show the update button if a new service worker is detected -->
      <button v-if="canShowUpdateButton " @click="updateApp" class="update-button mt-2">
        🔄 Update App
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        deferredPrompt: null, // Deferred prompt for installing the app
        canShowInstallButton: false, // Flag to show the install/reinstall button
        buttonText: "Install App", // Text of the button (Install or Reinstall)
        canShowUpdateButton: false, // Flag to show the update button
        waitingServiceWorker: null, // Store the waiting service worker
      };
    },
    mounted() {
      // Detect beforeinstallprompt for Chrome/Edge
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault(); // Prevent automatic prompt
        this.deferredPrompt = event; // Store the event for later
        this.canShowInstallButton = true; // Show the install button
        this.buttonText = "Install App"; // Set the text to "Install"
        console.log("App can be installed.");
      });
  
      // Detect appinstalled event to hide the install button after installation
      window.addEventListener("appinstalled", () => {
        this.canShowInstallButton = false; // Hide the button after installation
        console.log("App installed successfully.");
      });
  
      // Detect visibility change to determine if the app is uninstalled
      document.addEventListener("visibilitychange", this.checkAppStatus);
  
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/service-worker.js").then((registration) => {
          // Listen for new service workers waiting to be activated
          if (registration.waiting) {
            this.canShowUpdateButton = true;
            this.waitingServiceWorker = registration.waiting;
          }

          // Listen for updates in the future
          registration.addEventListener("updatefound", () => {
            const newWorker = registration.installing;
            newWorker.addEventListener("statechange", () => {
              if (newWorker.state === "installed" && navigator.serviceWorker.controller) {
                this.canShowUpdateButton = true;
                this.waitingServiceWorker = newWorker;
              }
            });
          });
        });

        // Handle service worker updates
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          window.location.reload();
        });
      }

      // Initial check when the component is mounted
      this.checkAppStatus();
    },
    methods: {
      // Handle install or reinstall based on the current state
      async handleInstall() {
        if (this.deferredPrompt) {
          // If the prompt is available (install scenario)
          console.log("Prompting PWA installation...");
          this.deferredPrompt.prompt(); // Show the install prompt
          const { outcome } = await this.deferredPrompt.userChoice;
          if (outcome === "accepted") {
            console.log("PWA installed successfully.");
            this.canShowInstallButton = false; // Hide the button after install
          } else {
            console.log("Installation canceled.");
          }
          this.deferredPrompt = null; // Reset the deferred prompt
        } else {
          // If no deferredPrompt, show a message to reinstall the app manually
          this.reinstallApp();
        }
      },
  
      // Handle reinstall scenario (for iOS or after uninstallation)
      reinstallApp() {
        alert("Please add this app back to your home screen by using your browser's menu.");
        // Optionally, you could show a message here on how to reinstall manually for iOS users.
      },
  
      // Check installation status using display-mode and visibilitychange
      checkAppStatus() {
        const isStandalone =
          window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;
  
        if (isStandalone) {
          // App is installed in standalone mode, hide the button
          this.canShowInstallButton = false;
        } else {
          // App is not installed, show the button
          this.canShowInstallButton = true;
          // If the app was previously installed and now uninstalled, show "Reinstall"
          this.buttonText = document.visibilityState === "visible" ? "Reinstall App" : "Install App";
        }
      },
  
      // Handle service worker change (new version available)
      onServiceWorkerChange() {
        console.log("New service worker detected, ready for update.");
        this.canShowUpdateButton = true; // Show the update button
      },
  
      // Handle the update process when the update button is clicked
      updateApp() {
        if (this.waitingServiceWorker) {
          console.log("Activating the new service worker...");
          this.waitingServiceWorker.postMessage({ type: "SKIP_WAITING" }); // Make the new worker take control immediately
          window.location.reload(); // Reload the page to apply the new version
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .install-button {
    background-color: #42b983;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .install-button:hover {
    background-color: #368f6f;
  }
  
  .update-button {
    background-color: #f39c12;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .update-button:hover {
    background-color: #e67e22;
  }
  </style>
  