<template>
    <div>
      <small v-if="lastUpdate">Last update: {{ lastUpdateText }}</small>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        error: null,
        errorsCount:0,
        lastUpdate: null,
        watchId: null,
        sendUpdates: true,
        throttleTimer:null,
        throttleDelay: 60000// in milliseconfs
      };
    },
    computed: {
      lastUpdateText() {
        return this.$moment(this.lastUpdate).fromNow();
      }
    },
    methods: {
        startUpdate() {
            if(!this.sendUpdates) return;
            if (!this.watchId) {
                const options = {
                    enableHighAccuracy: true,
                    timeout: 5000, // 5 seconds
                    maximumAge: 60000,//1 minutes
                };
                this.watchId = navigator.geolocation.watchPosition(
                    this.updatePosition,
                    this.handlePositionError,
                    options
                );
            }
        },
        stopUpdate() {
            try{
                navigator.geolocation.clearWatch(this.watchId);
            }catch(e){
                console.log(e);
            }
            this.throttleTimer=null;
            this.watchId = null;
        },
        updatePosition(position){
            if (!this.sendUpdates) return;
            // Throttle updates by delaying execution
            if (!this.throttleTimer) {
                this.throttleTimer = setTimeout(() => {
                    this.throttleTimer = null;
                    if(this.sendUpdates){
                        this.sendLocationUpdate(position);
                    }
                }, this.throttleDelay);
            }
        },
        sendLocationUpdate(position) {
            try{
                const { latitude, longitude } = position.coords;
                const url = `driver/realtime/location`;
                this.$axios.put(url, {
                    lt: latitude,
                    ln: longitude,
                    kmh:0,
                })
                .then(async () => {
                    this.lastUpdate = this.$moment();
                    //console.log(this.lastUpdate.format("YYYY-MM-DD HH:mm:ss"))
                })
                .catch(()=>{
                    this.errorsCount++;
                })
            }catch(err){
                this.errorsCount++;
                console.log(err);
            }     
        },
        handlePositionError(error) {
            this.errorsCount++;
            console.log(error);
        }
    },
    beforeDestroy() {
        // Stop watching the user's position when the component is destroyed
        this.stopUpdate();
    },
    mounted(){
        this.startUpdate();
    },
    watch: {
		errorsCount(newValue) {
			if(newValue >=5){
                this.stopUpdate();
                this.errorsCount=0;
            }
		},
	}
  };
  </script>
  